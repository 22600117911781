import React, { Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";
import Layout from "../Layout";

// Lazy load the components
const ListingHome = lazy(() => import("../listing/ListingHome"));
const SearchIndex = lazy(() => import("../search"));
const LoanOfficerSearchIndex = lazy(() => import("../loListingSearch"));

function ListingRoutes() {
  return (
    <React.Fragment>
      <Layout>
        <Suspense fallback={<div />}>
          <Routes>
            <Route exact path="/:listingId" element={<ListingHome />} />
            <Route exact path="/search/:agentName" element={<LoanOfficerSearchIndex />} />
            <Route exact path="/search" element={<SearchIndex />} />
          </Routes>
        </Suspense>
      </Layout>
    </React.Fragment>
  );
}

export default ListingRoutes;