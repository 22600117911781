import React from "react";
import CacheBuster from "react-cache-buster";
import ListingRoutes from "./routes/ListingRoutes";
import appversion from "../package.json";

function App() {
  const appVersion = appversion.version;
  const isProduction = process.env.NODE_ENV === "production";

  return (
    <CacheBuster
      currentVersion={appVersion}
      isEnabled={isProduction} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      metaFileDirectory={process.env.PUBLIC_URL}
    >
      <ListingRoutes />
    </CacheBuster>
  );
}

export default App;
