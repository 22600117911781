/*
 * @license
 * Copyright© Celligence International, LLC - 2023
 *
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
 *
 */
import React, { Suspense, lazy } from "react";
import Container from "react-bootstrap/Container";
const ScrollUp = lazy(() => import("../src/ScrollUp"));

const Layout = ({ children }) => {
    return (
        <Container
            fluid
            className="ps-0 pe-0"
        >
            {children}
            <Suspense fallback={<div />}>
                <ScrollUp />
            </Suspense>
        </Container>
    );
};

export default Layout;