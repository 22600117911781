import React, { createContext, useContext, useState } from 'react';

const BuydownContext = createContext();

export const BuydownProvider = ({ children }) => {
  const [buydownPermanentOptData, setBuydownPermanentOptData] = useState(null);
  const [buydown321OptData, setBuydown321OptData] = useState(null);
  const [buydown21OptData, setBuydown21OptData] = useState(null);

  return (
    <BuydownContext.Provider
      value={{
        buydownPermanentOptData,
        setBuydownPermanentOptData,
        buydown321OptData,
        setBuydown321OptData,
        buydown21OptData,
        setBuydown21OptData,
      }}
    >
      {children}
    </BuydownContext.Provider>
  );
};

export const useBuydown = () => {
  return useContext(BuydownContext);
};
